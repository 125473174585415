import "./App.css";
import TopNavV1 from "./Components/TopNav_V1";
import SideNavV1 from "./Components/SideNav_V1";
import HomeV1 from "./Components/Home_V1";
import AboutV1 from "./Components/About_V1";
import ContactV1 from "./Components/Contact_V1";

function App() {
  return (
    <div className="App">
      <TopNavV1 />
      <SideNavV1 />
      <HomeV1 />
      <AboutV1 />
      <ContactV1 />
    </div>
  );
}

export default App;
