import "../CSS/SideNav_V1.css";
import home from "../Images/home.svg";
import { Link } from "react-scroll";

function SideNavV1() {
  return (
    <nav className="sidenav">
      <Link
        to="homev1"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        activeStyle={{ display: "none" }}>
        <img src={home} alt="home" className="sidenav_home" />
      </Link>

      <Link to="contactv1" spy={true} smooth={true} offset={0} duration={500}>
        <h1>get in touch</h1>
      </Link>
    </nav>
  );
}

export default SideNavV1;
