import { useState, useEffect } from "react";

import "../CSS/Home_V1.css";
import arrow from "../Images/arrow.svg";
import { Link } from "react-scroll";

function HomeV1() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const ISTOffset = 330; // IST is UTC+5:30
  const ISTTime = new Date(time.getTime() + ISTOffset * 60 * 1000);

  const hours = ISTTime.getUTCHours().toString().padStart(2, "0");
  const minutes = ISTTime.getUTCMinutes().toString().padStart(2, "0");
  const seconds = ISTTime.getUTCSeconds().toString().padStart(2, "0");

  return (
    <section className="home_v1" id="homev1">
      <div className="home_v1_time">
        <p>
          IST {hours}:{minutes}:{seconds}
        </p>
      </div>

      <h1>
        Recode <br /> Inc.
      </h1>

      <Link
        className="home_v1_arrow"
        to="infov1"
        smooth={true}
        offset={window.innerWidth < 620 ? -68 : 0}
        duration={500}>
        <img src={arrow} alt="arrow" />
      </Link>
    </section>
  );
}

export default HomeV1;
