import "../CSS/Contact_V1.css";
import arrow from "../Images/arrow-right.svg";
import { db } from "../firebase";

function ContactV1() {
  function handleSubmit(event) {
    event.preventDefault();

    const details = document.querySelectorAll(".contact_v1 input");
    const subject = details[0];
    const fname = details[1];
    const lname = details[2];
    const email = details[3];
    const phone = details[4];
    const message = details[5];

    if (
      !subject.value ||
      !fname.value ||
      !lname.value ||
      !email.value ||
      !phone.value
    ) {
      alert("Please fill all the details!");
    } else {
      const data = {
        subject: subject.value,
        fname: fname.value,
        lname: lname.value,
        phone: parseInt(phone.value),
        message: message.value,
      };
      db.collection("contacts").doc(email.value).set(data);

      // clean up all the input fields
      subject.value = "";
      fname.value = "";
      lname.value = "";
      email.value = "";
      phone.value = "";
      message.value = "";

      // display success message popup
      const msg = document.querySelector(".contact_confirm");
      msg.style.opacity = "1";
      setTimeout(() => {
        msg.style.opacity = "0";
      }, 2500);
    }
  }

  return (
    <section className="contact_v1" id="contactv1">
      <h1>
        Getting to know each other? Working together? Meet up? We would love to
        hear from you.
      </h1>

      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="SUBJECT" required />

        <div className="input_container">
          <input type="text" placeholder="FIRST NAME" required />
          <input type="text" placeholder="LAST NAME" required />
        </div>

        <div className="input_container">
          <input type="email" placeholder="E-MAIL" required />
          <input type="number" placeholder="PHONE" required />
        </div>

        <input type="text" placeholder="MESSAGE" />

        <button type="submit">
          SEND <img src={arrow} alt="arrow" />
        </button>
      </form>

      <p className="contact_confirm">
        Thank you for reaching out to us. We will get in touch with you soon. :)
      </p>
    </section>
  );
}

export default ContactV1;
