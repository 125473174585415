import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDp7INSprtP8q1-OfhSPETuVbgIt_ZuNPs",
  authDomain: "recode-inc.firebaseapp.com",
  projectId: "recode-inc",
  storageBucket: "recode-inc.appspot.com",
  messagingSenderId: "206302690151",
  appId: "1:206302690151:web:965a5255403e34d010cdc2",
  measurementId: "G-N30NYM3C8M",
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export { db };
