import "../CSS/About_V1.css";
import creative from "../Images/creative.png";

function AboutV1() {
  return (
    <section className="info_v1" id="infov1">
      <div className="marquee">
        <div className="track">
          <div>
            OUR SITE IS CURRENTLY UNDER MAINTENANCE. ;) &nbsp;&nbsp;OUR SITE IS
            CURRENTLY UNDER MAINTENANCE. ;) &nbsp;&nbsp;OUR SITE IS CURRENTLY
            UNDER MAINTENANCE. ;) &nbsp;&nbsp;OUR SITE IS CURRENTLY UNDER
            MAINTENANCE. ;) &nbsp;&nbsp;
          </div>
        </div>
      </div>

      <div className="info_v1_container">
        <h1>
          We are a <br /> <span>creative</span> <br /> development
          <br /> studio
        </h1>
        <img src={creative} alt="creative" />
      </div>
    </section>
  );
}

export default AboutV1;
