import "../CSS/TopNav_V1.css";
import { useEffect } from "react";
import { Link } from "react-scroll";

import menu from "../Images/menu.png";
import cross from "../Images/cross.png";

function TopNavV1() {
  useEffect(() => {
    const menuIcon = document.querySelector(".topnav > img");
    const menu = document.querySelector(".topnav_menu");
    const topnav = document.querySelector(".topnav");
    const cross = document.querySelector(".topnav_cross");

    let menuOn = false;
    menuIcon.addEventListener("click", () => {
      menu.style.transform = "translateY(0)";
      topnav.style.backgroundColor = "#000000";
      topnav.style.color = "#ffffff";

      cross.style.display = "inline-block";
      menuIcon.style.display = "none";

      menuOn = !menuOn;
    });

    const handleMenuClose = () => {
      menu.style.transform = "translateY(-100vh)";

      setTimeout(() => {
        topnav.style.backgroundColor = "#ffffff";
        topnav.style.color = "#000000";
      }, 220);

      cross.style.display = "none";
      menuIcon.style.display = "inline-block";

      menuOn = false;
    };

    menu.addEventListener("click", handleMenuClose);
    cross.addEventListener("click", handleMenuClose);
  }, []);

  return (
    <div className="topnav">
      <h1>
        Recode <br /> Inc.
      </h1>
      <img src={menu} alt="menu" />
      <img src={cross} alt="cross" className="topnav_cross" />

      <ul className="topnav_menu">
        <li>
          <Link
            to="homev1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}>
            HOME
          </Link>
        </li>
        <li>
          <Link
            to="infov1"
            spy={true}
            smooth={true}
            offset={-68}
            duration={500}>
            ABOUT US
          </Link>
        </li>
        <li>
          <Link
            to="contactv1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            activeStyle={{ color: "#FF7628" }}>
            GET IN TOUCH
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default TopNavV1;
